import React, { useContext } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { Image, Grid } from 'semantic-ui-react';

import Context from '../../../context';
import t from '../../../locales/fi';
import { PaymentMethods, mobilePayEnabled } from '../../../utils/consts';
import { parsePaymentMethodsFromOffer } from '../../../utils/util';

const PaymentSelection = () => {
  const context = useContext(Context);
  const {
    offer,
    customer,
  } = context;
  const { options, magazines } = offer;
  const { getValues } = useFormContext();
  const { paymentMethod, selectedMagazineId } = getValues();
  const { control } = useFormContext();
  const { field } = useController({ control, name: 'paymentMethod' });

  const selectedMagazine = magazines.find((x) => x.id === selectedMagazineId);
  // For now, allow also mobilePay when card payment is allowed
  const allowedMethods = parsePaymentMethodsFromOffer(offer);
  const creditCardInfoText = options?.creditcardInfoText || '';
  const creditcardInfoDurationText
    = options?.creditcardInfoDurationText || '';
  const bankInfoText = options?.bankPaymentInfoText || '';
  const bankPaymentInfoDurationText = options?.bankPaymentInfoDurationText || '';
  const mobilePayInfoText = options?.mobilePayInfoText || '';
  const mobilePayInfoTextKesto = options?.mobilePayDurationText || '';
  const invoiceInfoText = options?.campaignpages?.invoice?.infoText || '';
  const bankIdentificationInfoText
    = options?.campaignpages?.invoice?.bankIdentificationText || '';
  const customerServiceIdentificationInfoText
    = options?.campaignpages?.invoice?.customerServiceIdentificationText || '';
  const tyrkyteExists = customer.tyrkyte?.tyrkyte || customer.tyrkyteId;
  if (allowedMethods.nothingAllowed) return null;

  const cardPaymentInformation = () => {
    if (paymentMethod === PaymentMethods.CARD && allowedMethods.card) {
      return (
        <Grid.Column
          id="paymentSelection-korttimaksu"
          className="payment-selection-info-column"
        >
          <div className="form-annotation">
            {creditCardInfoText && creditCardInfoText !== '' ? (
              <>
                <p>{creditCardInfoText}</p>
                {selectedMagazine
                  && selectedMagazine.salesType !== 'Määräaikainen'
                  && creditcardInfoDurationText && <p>{creditcardInfoDurationText}</p>}
              </>
            ) : (
              <p>
                Voit maksaa tilauksesi seuraavilla luottokorteilla: Visa, Visa
                Electron, MasterCard, Eurocard. Maksujen välitykseen käytetään
                PayEx-palvelua. Korttimaksu veloitetaan automaattisesti
                kortiltasi uuden tilausjakson alkaessa. Tilauksen voi irtisanoa
                koska tahansa tilausehtojen mukaisesti.
              </p>
            )}
          </div>
        </Grid.Column>
      );
    }
    return null;
  };

  const mobilePayInformation = () => {
    if (
      paymentMethod === PaymentMethods.MOBILEPAY
      && allowedMethods.mobilepay
    ) {
      return (
        <Grid.Column
          id="paymentSelection-mobilepay"
          className="payment-selection-info-column"
        >
          <div className="form-annotation">
            {mobilePayInfoText && mobilePayInfoText !== '' && (
              <>
                <p>{mobilePayInfoText}</p>
                {selectedMagazine
                  && selectedMagazine.salesType !== 'Määräaikainen'
                  && mobilePayInfoTextKesto && <p>{mobilePayInfoTextKesto}</p>}
              </>
            )}
          </div>
        </Grid.Column>
      );
    }
    return null;
  };

  const invoicePaymentInformation = () => {
    if (
      (paymentMethod === PaymentMethods.INVOICING_CALL
        || paymentMethod === PaymentMethods.INVOICING_E_IDENT)
      && allowedMethods.invoice
    ) {
      if (paymentMethod === PaymentMethods.INVOICING_E_IDENT) {
        return (
          <Grid.Column
            id="paymentSelection-lasku"
            className="payment-selection-info-column"
          >
            <div className="form-annotation">
              <p>{bankIdentificationInfoText}</p>
              <p>{invoiceInfoText}</p>
            </div>
          </Grid.Column>
        );
      }

      if (paymentMethod === PaymentMethods.INVOICING_CALL) {
        return (
          <Grid.Column
            id="paymentSelection-lasku"
            className="payment-selection-info-column"
          >
            <div className="form-annotation">
              <p>{customerServiceIdentificationInfoText}</p>
              <p>{invoiceInfoText}</p>
            </div>
          </Grid.Column>
        );
      }
    }
    return null;
  };

  const payTrailPaymentInformation = () => {
    if (
      paymentMethod === PaymentMethods.INVOICING_PAYTRAIL
      && allowedMethods.paytrail
    ) {
      return (
        <Grid.Column
          id="paymentSelection-lasku"
          className="payment-selection-info-column"
        >
          <div className="form-annotation">
            {bankInfoText && bankInfoText !== '' ? (
              <>
                <p>{bankInfoText}</p>
                {selectedMagazine
                  && selectedMagazine.salesType !== 'Määräaikainen'
                  && bankPaymentInfoDurationText && <p>{bankPaymentInfoDurationText}</p>}
              </>
            ) : (
              <p>{t.DEFAULT_BANK_INFO_TEXT}</p>
            )}
          </div>
        </Grid.Column>
      );
    }
    return null;
  };

  return (
    <Grid className="paymentMethod" id="paymentSelection">
      <Grid.Row className="no-padding">
        <Grid.Column>
          <h2 className="p-0">
            {allowedMethods.card && allowedMethods.invoice
              ? 'Valitse maksutapa'
              : 'Maksutapa'}
          </h2>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className="pt-0 pb-0">
        <Grid.Column width={16}>
          <div className="payment-option-container">
            <Grid celled className="mb-0 pb-0">
              {allowedMethods.card && (
                <Grid.Row>
                  <Grid.Column
                    width={16}
                    textAlign="left"
                    onClick={() => field.onChange(PaymentMethods.CARD)}
                    className="p-10"
                  >
                    <div className="payment-option-box">
                      <label
                        htmlFor={PaymentMethods.CARD}
                        className="card-payment-label"
                      >
                        <input
                          onChange={(event) => field.onChange(event)}
                          name={field.name}
                          type="radio"
                          id={PaymentMethods.CARD}
                          value={PaymentMethods.CARD}
                          ref={field.ref}
                          checked={field.value === PaymentMethods.CARD}
                        />
                        <span className="payment-label">{t.CARD_PAYMENT}</span>
                      </label>
                      <div className="card-image-wrapper">
                        <Image src="/images/Visa_44x28.png" alt="Maksutavat" />
                        <Image
                          src="/images/MasterCard_44x28.png"
                          alt="Maksutavat"
                        />
                      </div>
                      {cardPaymentInformation()}
                    </div>
                  </Grid.Column>
                </Grid.Row>
              )}

              {mobilePayEnabled && allowedMethods.mobilepay && (
                <Grid.Row>
                  <Grid.Column
                    width={16}
                    textAlign="left"
                    onClick={() => field.onChange(PaymentMethods.MOBILEPAY)}
                    className="p-10"
                  >
                    <div className="payment-option-box">
                      <label
                        htmlFor={PaymentMethods.MOBILEPAY}
                        className="card-payment-label"
                      >
                        <input
                          onChange={(event) => field.onChange(event)}
                          name={field.name}
                          type="radio"
                          id={PaymentMethods.MOBILEPAY}
                          value={PaymentMethods.MOBILEPAY}
                          ref={field.ref}
                          checked={field.value === PaymentMethods.MOBILEPAY}
                        />
                        <span className="payment-label">
                          {t.MOBILEPAY_PAYMENT}
                        </span>
                      </label>
                      <div className="card-image-wrapper">
                        <Image src="/images/MP_Logo_Blue.png" alt="MobilePay" />
                      </div>
                      {mobilePayInformation()}
                    </div>
                  </Grid.Column>
                </Grid.Row>
              )}

              {allowedMethods.paytrail && (
                <Grid.Row>
                  <Grid.Column
                    width={16}
                    textAlign="left"
                    onClick={() =>
                      field.onChange(PaymentMethods.INVOICING_PAYTRAIL)
                    }
                    className="p-10"
                  >
                    <div className="payment-option-box">
                      <label htmlFor={PaymentMethods.INVOICING_PAYTRAIL}>
                        <input
                          name={field.name}
                          type="radio"
                          id="bankPayment"
                          value={PaymentMethods.INVOICING_PAYTRAIL}
                          onChange={(event) => field.onChange(event)}
                          ref={field.ref}
                          checked={
                            field.value === PaymentMethods.INVOICING_PAYTRAIL
                          }
                        />
                        <span className="payment-label">{t.BANK_PAYMENT}</span>
                      </label>
                    </div>
                    {payTrailPaymentInformation()}
                  </Grid.Column>
                </Grid.Row>
              )}

              {allowedMethods.invoice && (
                <Grid.Row>
                  <Grid.Column
                    width={16}
                    textAlign="left"
                    onClick={() =>
                      field.onChange(PaymentMethods.INVOICING_E_IDENT)
                    }
                    className="p-10"
                  >
                    <div className="payment-option-box">
                      <label htmlFor={PaymentMethods.INVOICING_E_IDENT}>
                        <input
                          onChange={(event) => field.onChange(event)}
                          name={field.name}
                          type="radio"
                          id={PaymentMethods.INVOICING_E_IDENT}
                          value={PaymentMethods.INVOICING_E_IDENT}
                          ref={field.ref}
                          checked={
                            field.value === PaymentMethods.INVOICING_E_IDENT
                          }
                        />
                        <span className="payment-label">
                          {t.INVOICE_PAYMENT_BANK}
                        </span>
                      </label>
                    </div>
                    {paymentMethod === PaymentMethods.INVOICING_E_IDENT
                      && invoicePaymentInformation()}
                  </Grid.Column>
                </Grid.Row>
              )}

              {allowedMethods.invoice && tyrkyteExists && (
                <Grid.Row>
                  <Grid.Column
                    width={16}
                    textAlign="left"
                    onClick={() =>
                      field.onChange(PaymentMethods.INVOICING_CALL)
                    }
                    className="p-10"
                  >
                    <div className="payment-option-box">
                      <label htmlFor={PaymentMethods.INVOICING_CALL}>
                        <input
                          onChange={(event) => field.onChange(event)}
                          name={field.name}
                          type="radio"
                          id={PaymentMethods.INVOICING_CALL}
                          value={PaymentMethods.INVOICING_CALL}
                          ref={field.ref}
                          checked={
                            field.value === PaymentMethods.INVOICING_CALL
                          }
                        />
                        <span className="payment-label">
                          {t.INVOICE_PAYMENT_CALL}
                        </span>
                      </label>
                    </div>
                    {paymentMethod === PaymentMethods.INVOICING_CALL
                      && invoicePaymentInformation()}
                  </Grid.Column>
                </Grid.Row>
              )}
            </Grid>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default PaymentSelection;
