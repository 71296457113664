import React, { useContext, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Card, Form } from 'semantic-ui-react';

import Context from '../../../context';
import DropdownSelection from './DropdownSelection';
import InputWrapper from '../../util/InputWrapper';
import NumberOfPaymentsField from './NumberOfPaymentsField';

import { formatPrice, FormatStartDate } from '../../../utils/util';

const MagazineSelectDropdown = () => {
  const context = useContext(Context);
  const { offer } = context;
  const { getValues, setValue } = useFormContext();
  const { selectedMagazineId } = getValues();
  const [open, setOpen] = useState(true);

  const { startBeginning, discountPercentage } = offer;

  const getMagazineOptions = () =>
    offer.magazines.map((magazine, index) => ({
      key: `${index}`,
      value: `${magazine.id}`,
      text: `${magazine.name} ${magazine.duration} ${
        magazine.orderDurationType
      } ${formatPrice(magazine.offerPrice, discountPercentage)}`,
    }));

  const setNumOfPayments = () => {
    const selectedMagazine = offer.magazines.find(
      (m) => m.id === selectedMagazineId,
    );
    if (selectedMagazineId) {
      setValue('numOfPayments', selectedMagazine.numOfPayments);
    }
  };

  const selectedMagazine = offer.magazines.find(
    (m) => m.id === selectedMagazineId,
  );
  return (
    <div className="magazineDropdownMenu">
      {offer.magazines && offer.magazines.length > 0 && (
        <DropdownSelection
          open={open}
          placeholder="Valitse tästä"
          name="selectedMagazineId"
          type="text"
          options={getMagazineOptions()}
          onChangeCallback={setNumOfPayments}
          onBlurCallback={() => setOpen(false)}
        />
      )}
      {selectedMagazine && (
        <div>
          {selectedMagazine.salesType !== ''
            && selectedMagazine.salesType !== 'Tuntematon' && (
            <Card.Description>
              {selectedMagazine.salesType}
              {FormatStartDate(
                startBeginning,
                selectedMagazine.orderStarts,
              )}
            </Card.Description>
          )}
          {parseFloat(selectedMagazine.offerPrice) > 0
            && (selectedMagazine.numOfPayments > 1
              || selectedMagazine.maxNumOfPayments > 1) && (
            <label>
              <Form.Field>
                  Maksuerät (maksimissaan{' '}
                {selectedMagazine.maxNumOfPayments
                  ? selectedMagazine.maxNumOfPayments
                  : selectedMagazine.numOfPayments}
                  ):
                <br />
                <NumberOfPaymentsField
                  placeholder="Valitse tästä"
                  name="numOfPayments"
                  type="number"
                  magazine={selectedMagazine}
                  offer={offer}
                />
              </Form.Field>
            </label>
          )}
          {!startBeginning
            && !selectedMagazine.orderStarts && (
            <label>
              <Form.Field>
                <br />
                  Alkamisaika:
                <br />
                <InputWrapper
                  placeholder={new Date().toISOString().slice(0, 10)}
                  name="startingDate"
                  type="text"
                  tabindex="-1"
                  magazine={selectedMagazine}
                />
              </Form.Field>
            </label>
          )}
        </div>
      )}
    </div>
  );
};

export default MagazineSelectDropdown;
