import React from 'react';
import Popup from 'reactjs-popup';
import { Grid, Image } from 'semantic-ui-react';

import { POPUP_TYPE, formatPrice } from '../../../utils/util';

const PopupContent = (props) => {
  const { type, gift, magazine, isOpen, closeModal } = props;
  const createMarkup = (string) => ({ __html: string });

  switch (type) {
    case POPUP_TYPE.GIFT:
    case POPUP_TYPE.GIFT_IMAGE:
      return (
        <Popup
          className="gift-popup"
          open={isOpen}
          closeOnDocumentClick
          onClose={closeModal}
        >
          <div className="modal">
            <a className="close" onClick={closeModal}>
              &times;
            </a>
            <Grid stackable className="no-margin no-padding">
              <Grid.Row>
                <Grid.Column
                  mobile={8}
                  tablet={8}
                  computer={8}
                  style={gift.giftImage ? null : { display: 'none' }}
                >
                  {gift.price && (
                    <>
                      {gift.price !== '0' ? (
                        <Image
                          src={gift.giftImage}
                          fluid
                          label={{
                            as: 'span',
                            color: 'red',
                            content: `Arvo ${formatPrice(gift.price)}`,
                            ribbon: true,
                          }}
                        />
                      ) : (
                        <Image src={gift.giftImage} fluid />
                      )}
                    </>
                  )}
                </Grid.Column>
                {gift.name && gift.giftDescription && (
                  <Grid.Column
                    mobile={gift.giftImage ? 8 : 16}
                    tablet={gift.giftImage ? 8 : 16}
                    computer={gift.giftImage ? 8 : 16}
                  >
                    <div className="popup-content-header">
                      <div className="header">{gift.name}</div>
                    </div>
                    <div
                      className="popup-content-text"
                      dangerouslySetInnerHTML={gift.giftDescription}
                    />
                  </Grid.Column>
                )}
              </Grid.Row>
            </Grid>
          </div>
        </Popup>
      );
    case POPUP_TYPE.MAGAZINE:
    case POPUP_TYPE.MAGAZINE_IMAGE:
      return (
        <Popup
          className="narrow-popup"
          open={isOpen}
          closeOnDocumentClick
          onClose={closeModal}
        >
          <div className="modal">
            <a className="close" onClick={closeModal}>
              &times;
            </a>
            <Grid stackable className="no-margin no-padding">
              <Grid.Row>
                <Grid.Column
                  mobile={8}
                  tablet={8}
                  computer={8}
                  style={magazine.packageImage ? null : { display: 'none' }}
                >
                  <Image src={magazine.packageImage} fluid />
                </Grid.Column>

                {magazine.name && magazine.packageDescription && (
                  <Grid.Column
                    mobile={magazine.packageImage ? 8 : 16}
                    tablet={magazine.packageImage ? 8 : 16}
                    computer={magazine.packageImage ? 8 : 16}
                  >
                    <div className="popup-content-header">
                      <div className="header">{magazine.name}</div>
                    </div>

                    <div
                      className="popup-main-text"
                      dangerouslySetInnerHTML={
                        magazine.packageDescription.__html
                          ? magazine.packageDescription
                          : createMarkup(magazine.packageDescription)
                      }
                    />

                    {magazine.packageExtraDescription && (
                      <div className="popup-extra-text-wrapper">
                        {magazine.packageExtraDescription.__html ? (
                          <div
                            className="popup-main-text"
                            dangerouslySetInnerHTML={
                              magazine.packageExtraDescription
                            }
                          />
                        ) : (
                          <div
                            className="popup-main-text"
                            dangerouslySetInnerHTML={createMarkup(
                              magazine.packageExtraDescription.__html.length > 0
                                ? magazine.packageExtraDescription
                                : '',
                            )}
                          />
                        )}
                      </div>
                    )}
                  </Grid.Column>
                )}
              </Grid.Row>
            </Grid>
          </div>
        </Popup>
      );
    default:
      return null;
  }
};

export default PopupContent;
