import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { Grid, Form } from 'semantic-ui-react';

import Context from '../../../context';
import CountrySelect from './CountrySelect';
import EmailInfoText from './EmailInfoText';
import InputWrapper from '../../util/InputWrapper';

import {
  countryShouldDisplayOrderAbroadText,
  orderAbroadText,
  formatPhoneNumber,
  Recipient,
} from '../../../utils/util';

const CompanyDetails = () => {
  const context = useContext(Context);
  const { customer } = context;
  const { getValues, setValue } = useFormContext();
  const {
    recipient,
    subscriberDetails: { payerCountry: subscriberCountry },
  } = getValues();

  if (customer.isLoadingCustomer) {
    return (
      <Grid id="companyDetails">
        <Grid.Row>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <br />
            <div className="ui active loader" />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  const preFilledCustomer = !!customer.customer.lastName;
  if (preFilledCustomer) {
    return (
      <Grid id="companyDetails-prefilled">
        <Grid.Row className="no-padding">
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <p>
              {customer.customer.lastName !== ''
                && `${customer.customer.lastName} `}
            </p>
            <p>{customer.customer.streetAddress} </p>
            <p>
              {customer.customer.zipCode} {customer.customer.postOffice}
            </p>
            <Form.Field width={16} className="prefilled-details-field">
              <InputWrapper
                placeholder="Yhteyshenkilön sähköpostiosoite"
                name="companyDetails.payerEmail"
                type="email"
              />
            </Form.Field>
            <Form.Field>
              <Grid.Column
                className="right floated"
                mobile={16}
                tablet={8}
                computer={8}
              >
                <div className="form-annotation form-annotation-email-address">
                  <EmailInfoText />
                </div>
              </Grid.Column>
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  return (
    <Grid id="companyDetails">
      <Grid.Row>
        <Grid.Column
          mobile={16}
          tablet={8}
          computer={8}
          className="recipient-selection-input-column"
        >
          <InputWrapper
            label="Yrityksen nimi *"
            name="companyDetails.payerLastName"
            type="text"
          />
        </Grid.Column>
        <Grid.Column
          mobile={16}
          tablet={8}
          computer={8}
          className="recipient-selection-input-column"
        >
          <InputWrapper
            label="Y-tunnus esim. 1234567-1"
            name="companyDetails.payerCompanyId"
            type="text"
          />
        </Grid.Column>
        <Grid.Column
          mobile={16}
          tablet={16}
          computer={16}
          className="recipient-selection-input-column"
        >
          <InputWrapper
            label="Osoite"
            name="companyDetails.payerStreetAddress"
            type="text"
          />
        </Grid.Column>
        <Grid.Column
          mobile={16}
          tablet={8}
          computer={8}
          className="recipient-selection-input-column"
        >
          <InputWrapper
            label="Postinumero"
            name="companyDetails.payerZipCode"
            type="text"
          />
        </Grid.Column>
        <Grid.Column
          mobile={16}
          tablet={8}
          computer={8}
          className="recipient-selection-input-column"
        >
          <InputWrapper
            label="Postitoimipaikka"
            name="companyDetails.payerCity"
            type="text"
          />
        </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <CountrySelect
            placeholder="Maa"
            name="companyDetails.payerCountry"
            type="text"
          />
        </Grid.Column>
        <Grid.Column
          mobile={16}
          tablet={8}
          computer={8}
          className="recipient-selection-input-column"
        >
          <InputWrapper
            label="Yhteyshenkilön nimi"
            name="companyDetails.payerCoName1"
            type="text"
          />
        </Grid.Column>
        <Grid.Column
          mobile={16}
          tablet={8}
          computer={8}
          className="recipient-selection-input-column"
        >
          <InputWrapper
            label="Yhteyshenkilön puhelinnumero"
            name="companyDetails.payerPhoneNumber"
            type="text"
            onBlur={(e) => {
              setValue('companyDetails.payerPhoneNumber', formatPhoneNumber(e.target.value));
            }}
          />
        </Grid.Column>
        <Grid.Column
          mobile={16}
          tablet={8}
          computer={8}
          className="recipient-selection-input-column"
        >
          <InputWrapper
            label="Yhteyshenkilön sähköpostiosoite"
            name="companyDetails.payerEmail"
            type="email"
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column
          className={
            customer.tyrkyte && customer.tyrkyte.masterId
              ? 'right floated'
              : 'right floated'
          }
          mobile={16}
          tablet={16}
          computer={16}
        >
          <div className="form-annotation">
            <EmailInfoText />
          </div>
          <br />
          {[
            Recipient.TO_FRIEND,
            Recipient.TO_SELF,
            Recipient.TO_COMPANY,
          ].includes(recipient)
            && countryShouldDisplayOrderAbroadText(subscriberCountry) && (
            <p style={{ color: 'red', fontSize: 16 }}>{orderAbroadText}</p>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default CompanyDetails;
