import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { Grid } from 'semantic-ui-react';

import { Element } from 'react-scroll';
import Context from '../../../context';
import MagazineImage from './MagazineImage';
import MagazineInfoText from './MagazineInfoText';

import { showPriceByOrderType, getBackgroundColor } from '../../../utils/util';
import { SCROLL_ELEMENTS } from '../../../utils/scrollUtils';

const MagazineSelectList = () => {
  const context = useContext(Context);
  const { offer } = context;
  const { register, getValues, setValue } = useFormContext();
  const { selectedMagazineId } = getValues();

  const { discountPercentage, kayakOrderType } = offer;

  const selectedMagazine = offer.magazines.find(
    (m) => m.id === selectedMagazineId,
  );

  const renderExtraText3 = (magazine) => {
    if (magazine.offerExtraImage) {
      if (magazine.offerExtraImage.includes('http')) {
        return <img src={magazine.offerExtraImage} />;
      }
      return (
        <p style={{ width: '40%', textAlign: 'right' }}>
          {magazine.offerExtraImage}
        </p>
      );
    }
    return null;
  };

  return (
    <Grid id="magazineSelectList">
      <Grid.Row className="no-padding-bottom">
        <Grid.Column width={16}>
          <div className="magazine-list-container">
            <Grid celled className="magazine-list-grid">
              {offer.magazines.map((magazine) => (
                <Grid.Row key={magazine.id} className="magazine-list-row">
                  <Grid.Column
                    width={16}
                    textAlign="left"
                    className="magazine-list-column"
                    onClick={() => setValue('selectedMagazineId', magazine.id)}
                  >
                    <div className="magazine-list-entry">
                      <input
                        type="radio"
                        name="selectedMagazineId"
                        className="magazine-list-button"
                        value={magazine.id}
                        {...register('selectedMagazineId')}
                      />
                      <p className="magazine-list-label">
                        {magazine.name} {magazine.duration}{' '}
                        {magazine.orderDurationType}{' '}
                        {showPriceByOrderType(
                          magazine.offerPrice,
                          discountPercentage,
                          kayakOrderType,
                        )}
                      </p>
                      {renderExtraText3(magazine)}
                    </div>
                  </Grid.Column>
                </Grid.Row>
              ))}
            </Grid>
          </div>
        </Grid.Column>
      </Grid.Row>
      <Element
        name={SCROLL_ELEMENTS.LIST_SELECTION.name}
        id={SCROLL_ELEMENTS.LIST_SELECTION.name}
      />
      {selectedMagazine && (
        <Grid.Row style={{ background: getBackgroundColor(null, offer) }}>
          <Grid.Column width={16}>
            <Grid centered>
              <Grid.Row textAlign="center">
                <Grid.Column computer={4} tablet={4} mobile={16}>
                  {selectedMagazine.packageImage && (
                    <MagazineImage
                      magazine={selectedMagazine}
                      className="mag-image-side-by-side"
                    />
                  )}
                </Grid.Column>
                <Grid.Column computer={4} tablet={4} mobile={16}>
                  <MagazineInfoText 
                    magazine={selectedMagazine} 
                    offer={offer}
                    singleItem />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      )}
    </Grid>
  );
};

export default MagazineSelectList;
