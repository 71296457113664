import React from 'react';
import { Grid, Image } from 'semantic-ui-react';

const PackageBanner = ({ offer, isTyrkyteForm }) => {
  if (!offer.banners) {
    return null;
  }
  const banner = isTyrkyteForm
    ? offer.banners.topBannerImage
    : offer.banners.topBannerImage2;
  const dontShowBanner = isTyrkyteForm
    ? offer.dontShowUpperLogo1
    : offer.dontShowUpperLogo2;
  const { topBannerImage2 } = offer.banners;

  // Rule 3
  if (!banner && !topBannerImage2) {
    return null;
  }

  // Rule 2
  if (!banner) {
    return (
      <div className="banner-no-padding">
        {(topBannerImage2 && !dontShowBanner) && (
          <Grid.Row className="banner-row">
            <span>
              <Image className="package-banner" src={topBannerImage2} fluid />
            </span>
            <br />
          </Grid.Row>
        )}
      </div>
    );
    // Rule 1
  }
  return (
    <>
      {banner && (!dontShowBanner) && (
        <Grid.Row className="banner-row">
          <span>
            <Image className="banner-image" src={banner} fluid />
          </span>
        </Grid.Row>
      )}
    </>
  );
};

export default PackageBanner;
