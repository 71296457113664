import React, { useState, useEffect, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { Grid } from 'semantic-ui-react';

import Context from '../../../context';
import BannerText from './BannerText';
import MagazineSelectBrand from './MagazineSelectBrand';
import MagazineSelectCarousel from './MagazineSelectCarousel';
import MagazineSelectDropdown from './MagazineSelectDropdown';
import MagazineSelectList from './MagazineSelectList';
import SliderArrow from './SliderArrow';

import * as ga from '../../../utils/googleAnalytics';
import {
  getBackgroundColor,
  Layout,
  resolveMagazineLayout,
} from '../../../utils/util';
import PackageBanner from '../../main/PackageBanner';

const RenderDescription = ({ offer }) => {
  const { __html: text } = offer.banners.bannerText;
  if (text && text.length > 0) {
    return (
      <div>
        <span dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    );
  }
  // Explicitly return null in case extraText null or undefined
  return null;
};

const MagazineSelection = (props) => {
  const { selectedBrandPackage, setSelectedBrandPackage, layout } = props;
  const context = useContext(Context);
  const { offer } = context;
  const { getValues } = useFormContext();
  const { selectedMagazineId } = getValues();
  const magazineLayout = layout || resolveMagazineLayout(offer);

  const [carouselExtraImage, setCarouselExtraImage] = useState(false);
  const [currentSelectedMagazineInfo, setCurrentSelectedMagazineInfo]
    = useState('');

  useEffect(() => {
    // Set carouselExtraImage if offer contains any magazine with extra image
    offer.magazines.forEach((magazine) => {
      if (magazine.offerExtraImage && magazine.offerExtraImage !== '') {
        setCarouselExtraImage(true);
      }
    });
  }, []);

  // Track on magazine selection for GA (not on TallennusForm)
  useEffect(() => {
    if (
      !(magazineLayout === Layout.DROPDOWN)
      && selectedMagazineId !== null
      && selectedMagazineId !== ''
      && selectedMagazineId !== currentSelectedMagazineInfo.id
    ) {
      const magazineInfo = offer.magazines.find(
        (mag) => mag.id === selectedMagazineId,
      );

      if (magazineInfo) {
        // Remove old mag (if any) from cart
        if (
          currentSelectedMagazineInfo !== ''
          && selectedMagazineId !== currentSelectedMagazineInfo.id
        ) {
          ga.googleAnalyticsRemoveFromCart(currentSelectedMagazineInfo);
        }
        // Add to cart and set state
        ga.googleAnalyticsAddToCart(magazineInfo);
        setCurrentSelectedMagazineInfo(magazineInfo);
      }
    }
  }, [selectedMagazineId]);

  const sliderSettings = {
    arrows: true,
    infinite: offer.magazines > 3,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          // if there are only 2 magazines slides are on the left not centered
          slidesToShow: offer.magazines > 2 ? 3 : 2,
          slidesToScroll: offer.magazines > 2 ? 3 : 2,
        },
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    nextArrow: <SliderArrow direction="right" />,
    prevArrow: <SliderArrow direction="left" />,
  };

  // TallennusPageOrderForm uses this
  if (magazineLayout === Layout.DROPDOWN) {
    return (
      <div id="magazineSelection" className="magazine-choice">
        <h2>Valitse tarjous</h2>
        <MagazineSelectDropdown />
      </div>
    );
  }

  return (
    <Grid id="magazineSelection" className="no-margin pb-30 padding-top-20">
      <Grid.Row className="no-padding">
        <Grid.Column className="no-padding">
          <PackageBanner offer={offer} isTyrkyteForm={false} />
        </Grid.Column>
      </Grid.Row>

      {/* Rule for extraTexts 1-2 and packageDescription to not show empty banner */}
      {offer.banners.bannerText && (!offer.hideExtraText1) && (
        <Grid.Row textAlign="center" style={{ paddingTop: 0 }}>
          <Grid.Column>
            <RenderDescription offer={offer} />
          </Grid.Column>
        </Grid.Row>
      )}

      <Grid.Row
        style={
          magazineLayout === Layout.LIST
            ? { padding: 0 }
            : { background: getBackgroundColor(magazineLayout, offer) }
        }
        textAlign="center"
        className="no-padding"
      >
        <Grid.Column width={16}>
          <h2>
            {offer?.orderHereText
              ? offer.orderHereText
              : 'Tuote'}
          </h2>
        </Grid.Column>

        <Grid.Column width={16} className="no-padding">
          {magazineLayout === Layout.LIST && <MagazineSelectList />}

          {magazineLayout === Layout.BRAND && (
            <MagazineSelectBrand
              sliderSettings={sliderSettings}
              selectedBrandPackage={selectedBrandPackage}
              setSelectedBrandPackage={setSelectedBrandPackage}
            />
          )}

          {magazineLayout === Layout.CAROUSEL && (
            <MagazineSelectCarousel
              sliderSettings={sliderSettings}
              carouselExtraImage={carouselExtraImage}
              magazines={offer.magazines}
              offer={offer}
            />
          )}
        </Grid.Column>
      </Grid.Row>
      {offer.banners.bannerText2 && (
        <Grid.Row className="no-padding-bottom">
          <Grid.Column width={16}>
            <BannerText offer={offer} />
          </Grid.Column>
        </Grid.Row>
      )}
    </Grid>
  );
};

export default MagazineSelection;
