import React, { useContext, useEffect } from 'react';
import { Grid } from 'semantic-ui-react';

import Context from '../../context';
import HeaderBar from '../main/HeaderBar';
import OtavamediaAccountStatus from '../main/OtavamediaAccountStatus';
import ThankYouContent from '../main/ThankYouContent';
import ThankYouContentPaywall from '../main/ThankYouContentPaywall';
import * as ga from '../../utils/googleAnalytics';
import { isDigiOrder as checkIfDigiOrder } from '../../utils/util';
import Footer from '../main/Footer';

const unSuccesfulSubscriptionContent = ({ isDigiOrder, subscription }) => (
  <div
    style={{ textAlign: 'center', minWidth: '0%', width: '100%' }}
    className="wrapper-box-shadow white-bg"
  >
    <h1>Kiitos tilauksesta!</h1>
    <br />
    <br />
    <p>Tilauksesi käsitellään asiakaspalvelussa.</p>

    {isDigiOrder && (
      <OtavamediaAccountStatus
        otavamediaAccount={subscription.otavamediaAccount}
      />
    )}
    <br />
  </div>
);

const OrderCompletePage = () => {
  useEffect(() => {
    ga.googleAnalyticsPageView();
  }, []);
  const context = useContext(Context);
  const { order, offer } = context;
  /*
   * Order and/or subscription not found in context
   */
  if (!order || !order.subscription) {
    return (
      <div id="orderCompletePage">
        <HeaderBar />
        <Grid.Row className="layout-container wrapper-box-shadow white-bg">
          <div style={{ textAlign: 'center', paddingTop: 30 }}>
            <p>Tilausta ei löydy...</p>
            <br />
          </div>
        </Grid.Row>
      </div>
    );
  }

  const { subscription } = order;
  const { order: orderObject } = subscription;
  const isDigiOrder = checkIfDigiOrder(
    subscription?.api2subscription?.productLines,
  );
  /*
   * Pay wall order. Use redirecting pay wall component.
   */

  // TODO refactor this to reduce code duplication
  if (orderObject.payWallOrder) {
    return (
      <>
        <div className="layout-container">
          <div>
            <div id="orderCompletePage">
              <ThankYouContentPaywall
                subscription={subscription}
                order={orderObject}
                offer={offer}
              />
            </div>
          </div>
        </div>
        <div className="footer-section">
          <Footer />
        </div>
      </>
    );
  }

  return (
    <>
      <div className="layout-container">
        <span>
          <HeaderBar />
          <div id="orderCompletePage">
            <Grid centered stackable>
              <Grid.Row>
                {!subscription.subscriptionSuccessful ? (
                  unSuccesfulSubscriptionContent({ subscription, isDigiOrder })
                ) : (
                  <ThankYouContent
                    subscription={subscription}
                    order={orderObject}
                    isDigiOrder={isDigiOrder}
                    options={subscription?.options}
                    offer={offer}
                  />
                )}
              </Grid.Row>
            </Grid>
          </div>
        </span>
      </div>
      <div className="footer-section">
        <Footer />
      </div>
    </>
  );
};

export default OrderCompletePage;
