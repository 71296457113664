import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Grid } from 'semantic-ui-react';

import Context from '../../context';
import GiftSelection from './formSections/GiftSelection';
import MagazineSelection from './formSections/MagazineSelection';
import OrderFormStepBar from '../main/OrderFormStepBar';
import OrderSummary from './formSections/OrderSummary';
import RecipientAndPaymentSelection from './formSections/RecipientAndPaymentSelection';
import ScrollElement from '../util/ScrollElement';

import {
  Recipient,
  setCompanyDetails,
  setGifts,
  setSubscriberDetails,
  parsePaymentMethodsFromOffer,
  shouldDisplayStudentNumberInput,
  formatPhoneNumber,
} from '../../utils/util';
import { ORDER_FORM_SCHEMA } from '../../utils/formValidator';
import { PaymentMethods } from '../../utils/consts';
import scrollToElement, {
  SCROLL_ELEMENTS,
  resolveStepBarScrollElements,
} from '../../utils/scrollUtils';
import useElementOnScreen, {
  getTargetRefByName,
} from '../../utils/useElementOnScreen';
import OrderingTerms from './formSections/OrderingTerms';

const OrderForm = (props) => {
  const { onSubmit } = props;
  const context = useContext(Context);
  const { offer, customer, order: { order: existingOrder } = {} } = context;
  const {
    magazines,
    gifts,
    isLoading,
    offeringId,
    params,
    layout,
    payerCustomerId,
  } = offer;
  const allowedMethods = parsePaymentMethodsFromOffer(offer);
  const url = new URL(window.location.href);
  const urlParams = new URLSearchParams(url.search);
  const formProps = {
    defaultValues: {
      isLoading,
      selectedMagazineId:
        existingOrder?.selectedMagazineId
        || (magazines.length === 1 ? magazines[0].id : ''),
      selectedGiftId:
        existingOrder?.gifts?.[0] || (gifts.length === 1 ? gifts[0].id : ''),
      recipient: payerCustomerId ? Recipient.TO_FRIEND : Recipient.TO_SELF,
      requireSubscriberInfo: !payerCustomerId, // In these cases, order is done with payerCustomerId which is set in backend
      requireStudentNumber: shouldDisplayStudentNumberInput(offeringId),
      paymentMethod: allowedMethods.nothingAllowed
        ? PaymentMethods.INVOICE
        : null,
      subscriberDetails: {
        payerMasterAsiakasId: customer.tyrkyte?.masterId || '',
        payerFirstName:
          customer.customer?.firstName || urlParams.get('payerFirstName') || '',
        payerLastName:
          customer.customer?.lastName || urlParams.get('payerLastName') || '',
        payerStreetAddress:
          customer.customer?.streetAddress
          || urlParams.get('payerStreetAddress')
          || '',
        payerCountry: customer.customer?.country || '',
        payerZipCode:
          customer.customer?.zipCode || urlParams.get('payerZipCode') || '',
        payerCity:
          customer.customer?.postOffice
          || customer.customer?.city
          || urlParams.get('payerCity')
          || '',
        payerPhoneNumber:
          customer.customer?.phoneNumber
          || formatPhoneNumber(urlParams.get('payerPhoneNumber'))
          || '',
        payerBirthday:
          customer.customer?.birthDay || urlParams.get('payerBirthday') || '',
        payerEmail:
          customer.customer?.email || urlParams.get('payerEmail') || '',
        payerStudentNumber: '',
      },
      recipientDetails: {
        recipientFirstName: '',
        recipientLastName: '',
        recipientStreetAddress: '',
        recipientCountry: 'FI',
        recipientZipCode: '',
        recipientCity: '',
        recipientEmail: '',
        recipientPhoneNumber: '',
      },
      companyDetails: {
        payerMasterAsiakasId: customer.tyrkyte?.masterId || '',
        payerLastName: customer.customer?.lastName || '',
        payerCompanyId: customer.customer?.companyId || '',
        payerStreetAddress: customer.customer?.streetAddress || '',
        payerCountry: customer.customer?.country || '',
        payerZipCode: customer.customer?.zipCode || '',
        payerCity: customer.customer?.postOffice || '',
        payerCoName1: customer.customer?.coName1 || '',
        payerPhoneNumber: customer.customer?.phone || '',
        payerEmail: '',
      },
      terms: false,
    },
    resolver: yupResolver(ORDER_FORM_SCHEMA),
    reValidateMode: 'onSubmit',
    criteriaMode: 'all',
  };
  if (params?.emailMarketingOption) {
    formProps.defaultValues.emailMarketingConsent = false;
  }
  const formMethods = useForm(formProps);
  const { selectedMagazineId, selectedGiftId, recipient } = formMethods.watch();
  const [payInSingleCharge, setPayInSingleCharge] = useState(false);
  const payInSingleChargeToggle = () =>
    setPayInSingleCharge(!payInSingleCharge);

  // Used by brand layout and scrolling
  const [selectedBrandPackage, setSelectedBrandPackage] = useState(null);
  useEffect(() => {
    const magazineIsSelectable = magazines.length > 1;
    const giftIsSelectable = gifts.length > 1;
    const isList = layout === 'singleCol' && magazines.length !== 0;
    // Magazine not selectable..
    if (!magazineIsSelectable) {
      // but gift is selectable and selected -> scroll to recipient selection
      if (giftIsSelectable && selectedGiftId) {
        scrollToElement(SCROLL_ELEMENTS.RECIPIENT_AND_PAYMENT_SELECTION.name);
      }
      // Layout is brand and brand is selected but no mag selected
    } else if (!selectedMagazineId && selectedBrandPackage) {
      scrollToElement(SCROLL_ELEMENTS.BRAND_SELECTION.name);
      // Magazine is selected..
    } else if (selectedMagazineId && !isList) {
      // Layout is list and magazine is selected -> scroll to selected magazine
      if (giftIsSelectable && selectedGiftId) {
        scrollToElement(SCROLL_ELEMENTS.RECIPIENT_AND_PAYMENT_SELECTION.name);
        // and gift is selectable but not selected -> scroll to gift selection
      } else if (giftIsSelectable) {
        scrollToElement(SCROLL_ELEMENTS.GIFT_SELECTION.name);
        // and gift not selectable -> scroll to recipient selection
      } else {
        scrollToElement(SCROLL_ELEMENTS.RECIPIENT_AND_PAYMENT_SELECTION.name);
      }
    } else if (isList) {
      // List offers are special cases - they should not cause scroll when magazine is chosen, but should when gift is chosen
      if (giftIsSelectable && selectedGiftId) {
        scrollToElement(SCROLL_ELEMENTS.RECIPIENT_AND_PAYMENT_SELECTION.name);
      }
    }
  }, [selectedMagazineId, selectedGiftId, selectedBrandPackage]);

  /*
   * OTVMSUP-758 StepBar nav stuff
   */
  const scrollElements = resolveStepBarScrollElements(offer);
  const [targets, observedElements] = useElementOnScreen(scrollElements);

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // <===== This stops the form from being submitted
    }
    offer.postError = false;
  };

  const titleUnderlineHandler = () => {
    const titles = document.getElementsByTagName('h1');
    if (titles) {
      Array.from(titles).forEach((title) => {
        if (title.scrollHeight > 50) {
          title.classList.add('no-before');
        } else {
          title.classList.remove('no-before');
        }
      });
    }
  };

  /**
   * OTVMSUP-171
   * After DOM has rendered add event listener to resize.
   * Removes (Adds 'no-before' class to element) underline from offering title if text overflows (e.g. text on 2 lines)
   */
  useLayoutEffect(() => {
    window.addEventListener('resize', titleUnderlineHandler);
    titleUnderlineHandler();
    return () => window.removeEventListener('resize', titleUnderlineHandler);
  }, []);

  const formSubmit = (data) => {
    if (recipient !== Recipient.TO_COMPANY) {
      onSubmit({
        ...setSubscriberDetails(customer, data.subscriberDetails),
        ...data.recipientDetails,
        ...data.GiftDetails,
        offeringId,
        selectedMagazineId: data.selectedMagazineId,
        payerCustomerId,
        packageId: data.packageId,
        origin: `${window.location.protocol}//${window.location.host}`,
        receiveType: params.receiveType === 'aspa' ? '05' : '04',
        recipient: data.recipient,
        paymentMethod: data.paymentMethod,
        customer: customer.customer,
        tyrkyteId: customer.tyrkyteId || customer.tyrkyte.tyrkyte,
        targetGroupId: customer.tyrkyte.targetGroupId
          ? customer.tyrkyte.targetGroupId
          : offer.targetGroupId,
        gifts: gifts.length > 0 ? setGifts(offer, data.selectedGiftId) : [],
        emailConsent: true,
        emailMarketingConsent: !data.emailMarketingConsent, // Empty checkbox means consent is true
        numOfPayments: payInSingleCharge ? 1 : null,
      });
    } else {
      onSubmit({
        ...setCompanyDetails(customer, data.companyDetails),
        ...data.recipientDetails,
        ...data.GiftDetails,
        offeringId,
        selectedMagazineId: data.selectedMagazineId,
        payerCustomerId,
        packageId: data.packageId,
        origin: `${window.location.protocol}//${window.location.host}`,
        receiveType: params.receiveType === 'aspa' ? '05' : '04',
        recipient: data.recipient,
        paymentMethod: data.paymentMethod,
        customer: customer.customer,
        tyrkyteId: customer.tyrkyteId,
        targetGroupId: customer.tyrkyte.targetGroupId
          ? customer.tyrkyte.targetGroupId
          : offer.targetGroupId,
        gifts: gifts.length > 0 ? setGifts(offer, data.selectedGiftId) : [],
        emailConsent: true,
        emailMarketingConsent: data.emailMarketingConsent,
        // magazine.numOfPayments is set in backend if this is not set
        numOfPayments: payInSingleCharge ? 1 : null,
      });
    }
  };

  return (
    <div id="orderForm">
      <FormProvider {...formMethods}>
        <Form
          onKeyPress={onKeyPress}
          onSubmit={formMethods.handleSubmit((data) => formSubmit(data))}
          className="order-form"
        >
          <OrderFormStepBar
            elements={scrollElements}
            observedElements={observedElements}
          />

          <Grid id="orderFormContainer">
            <ScrollElement
              name={SCROLL_ELEMENTS.MAGAZINE_SELECTION.name}
              targetRef={getTargetRefByName(
                targets,
                SCROLL_ELEMENTS.MAGAZINE_SELECTION.name,
              )}
            />
            <Grid.Row className="order-form-step-container-row">
              <Grid.Column
                mobile={16}
                tablet={16}
                computer={16}
                className="order-form-step-container-column"
              >
                <MagazineSelection
                  selectedBrandPackage={selectedBrandPackage}
                  setSelectedBrandPackage={setSelectedBrandPackage}
                />
              </Grid.Column>
            </Grid.Row>

            <ScrollElement
              name={SCROLL_ELEMENTS.GIFT_SELECTION.name}
              targetRef={getTargetRefByName(
                targets,
                SCROLL_ELEMENTS.GIFT_SELECTION.name,
              )}
            />
            {gifts.length > 0 && (
              <Grid.Row className="order-form-step-container-row">
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={16}
                  className="order-form-step-container-column"
                >
                  <GiftSelection />
                </Grid.Column>
              </Grid.Row>
            )}

            <ScrollElement
              name={SCROLL_ELEMENTS.RECIPIENT_AND_PAYMENT_SELECTION.name}
              targetRef={getTargetRefByName(
                targets,
                SCROLL_ELEMENTS.RECIPIENT_AND_PAYMENT_SELECTION.name,
              )}
            />
            <Grid.Row className="order-form-step-container-row">
              <Grid.Column
                mobile={16}
                tablet={16}
                computer={16}
                className="order-form-step-container-column"
              >
                <RecipientAndPaymentSelection recipient={recipient} />
              </Grid.Column>
            </Grid.Row>

            <ScrollElement
              name={SCROLL_ELEMENTS.ORDER_SUMMARY.name}
              targetRef={getTargetRefByName(
                targets,
                SCROLL_ELEMENTS.ORDER_SUMMARY.name,
              )}
            />
            <Grid.Row className="order-form-step-container-row">
              <Grid.Column
                mobile={16}
                tablet={16}
                computer={16}
                className="order-form-step-container-column"
              >
                <OrderSummary
                  payInSingleChargeToggle={() => payInSingleChargeToggle()}
                />
              </Grid.Column>
            </Grid.Row>
            <OrderingTerms />
          </Grid>
        </Form>
      </FormProvider>
    </div>
  );
};

export default OrderForm;
