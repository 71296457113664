/*
 * Util class for GA functions.
 *
 * Tag Manager and Analytics are used to track customer actions.
 * GTM does some automatic tracking but we are mainly interested in
 * ecommerce events that are pushed manually.
 *
 * All events can be viewed in browser console by inspecting dataLayer object.
 * window.dataLayer is initialized along with GTM scripts in index.html <head> -tag.
 *
 * Manually tracked (ecommerce) events:
 * 1. AddToCart: Customer adds item to "cart" a.k.a chooses magazine in MagazineChoice.jsx
 * 2. RemoveFromCart: Customer chooses different magazine. AddToCart is called again right after.
 * 3. MasterAsiakasId: Customer is found with tyrkyte. Adds masterasiakasId to dataLayer.
 * 4. Purchase: Called after successful campaign page orders.
 * 5. PaywallPurchase: Called after successful paywall orders.
 * 6. offeringIdAndBrand: push offeringId and offerBrand to datalayer when user visits landing page (OTVMSUP-104).
 */

exports.googleAnalyticsAddToCart = (magazine) => {
  const offerCategory = getOfferCategory(magazine.packageId);
  const offerBrands = getOfferBrands(magazine);
  window.dataLayer.push({
    event: 'addToCart',
    ecommerce: {
      add: {
        actionField: {
          list: 'Shopping cart',
        },
        products: [
          {
            id: magazine.packageId,
            name: `${magazine.name} ${magazine.duration} ${magazine.orderDurationType}`,
            brand: offerBrands,
            category: offerCategory,
            price: parseFloat(magazine.offerPrice),
            quantity: 1,
            variant: magazine.salesType,
          },
        ],
      },
    },
  });
};

exports.googleAnalyticsRemoveFromCart = (magazine) => {
  const offerCategory = getOfferCategory(magazine.packageId);
  const offerBrands = getOfferBrands(magazine);
  window.dataLayer.push({
    event: 'removeFromCart',
    ecommerce: {
      add: {
        actionField: {
          list: 'Shopping cart',
        },
        products: [
          {
            id: magazine.packageId,
            name: `${magazine.name} ${magazine.duration} ${magazine.orderDurationType}`,
            brand: offerBrands,
            category: offerCategory,
            price: parseFloat(magazine.offerPrice),
            quantity: 1,
            variant: magazine.salesType,
          },
        ],
      },
    },
  });
};

exports.googleAnalyticsMasterasiakasId = (masterId) => {
  try {
    window.dataLayer.push({
      event: 'tyrkyte',
      ecommerce: {
        tyrkyte: {
          actionField: {
            masterasiakasId: masterId || null,
          },
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
};

exports.googleAnalyticsPurchase = (response) => {
  try {
    if (response && response.magazine) {
      const orderedMagazine = response.magazine;
      const offerCategory = getOfferCategory(orderedMagazine.packageId);
      const offerBrands = getOfferBrands(orderedMagazine);

      let name = `${orderedMagazine.name} ${orderedMagazine.duration} ${orderedMagazine.orderDurationType}`;
      name = response.order.payWallOrder ? `${name} MM` : name;

      window.dataLayer.push({
        event: 'purchase',
        ecommerce: {
          purchase: {
            actionField: {
              id: `${response.api2subscription.orderSubscriptionId}`,
              revenue: orderedMagazine.offerPrice,
            },
            products: [
              {
                id: orderedMagazine.packageId,
                name,
                brand: offerBrands,
                category: offerCategory,
                price: parseFloat(orderedMagazine.offerPrice),
                quantity: 1,
                variant: orderedMagazine.salesType,
              },
            ],
          },
        },
      });
    }
  } catch (e) {
    console.log(e);
  }
};

exports.googleAnalyticsPaywallPurchase = () => {
  window.dataLayer.push({
    event: 'engage.dp.init',
    'engage.track': 'article.purchase',
  });
};

/**
 * Used to manually fire GA pageview event for react view changes.
 *
 * Use this in useEffect on every root component (../components/views/*)
 */
exports.googleAnalyticsPageView = () => {
  window.dataLayer.push({
    event: 'pageview',
    pagePath: `${window.location.host}${window.location.pathname}`,
    pageTitle: document.title,
  });
};

exports.googleAnalyticsOfferingIdAndBrand = (id, brand) => {
  window.dataLayer.push({
    event: 'allDone',
    offerBrand: brand,
    offeringId: id,
  });
};

/*
 * Private functions
 */
const getOfferBrands = (magazine) => {
  let result = '';
  magazine.productLines.map((product, index) => {
    if (index === 0) {
      result += `${product.paperCode}`;
    } else {
      result += ` + ${product.paperCode}`;
    }
  });
  return result;
};

const getOfferCategory = (packageid) => {
  let result = 'MUUT';
  if (packageid.includes('PRINTTI')) {
    result = 'lehti';
  } else if (packageid.includes('DIGI')) {
    result = 'digi';
  } else if (packageid.includes('COMBO')) {
    result = 'combo';
  }
  return result;
};
