import React, { useContext } from 'react';
import Context from '../../context';
import { logoSrcByBrand } from '../../utils/logoUtils';

const HeaderBar = () => {
  const { offer } = useContext(Context);
  const { brandsOnOffering } = offer || {};

  return (
    <div className="header-bar">
      <img
        className="header-bar-logo"
        src={logoSrcByBrand(
          (brandsOnOffering &&
            brandsOnOffering?.length === 1 &&
            brandsOnOffering[0]) ||
            'OTAVAMEDIA',
        )}
        alt="Lehden tai Otavamedian logo"
      />
    </div>
  );
};

export default HeaderBar;
