import React from 'react';

/**
 * Text to be shown under banner image on order form.
 * Its magazine.packageDescription of resolved package or extraText1 if it was present.
 * All calculation is done in backend so its enough to show text if found.
 *
 * @param offer
 * @return BannerText element
 * @constructor
 */
const BannerText = ({ offer }) => {
  const textOrHtml = offer.banners.bannerText2;

  // Description contains html i.e. ({__html: '<div>example</div>'})
  if (textOrHtml && textOrHtml.hasOwnProperty('__html')) {
    return (
      <p
        className="banner-text-wrapper"
        id="BannerText"
        dangerouslySetInnerHTML={textOrHtml}
      />
    );
  }
  // Plain text
  if (textOrHtml) {
    return (
      <p id="BannerText" className="banner-text-wrapper">
        {textOrHtml}
      </p>
    );
  }
  // Description was not present
  return null;
};

export default BannerText;
