import React, { useContext } from 'react';

import Context from '../../../context';
import CheckBoxField from './CheckBoxField';

const Legal = () => {
  const context = useContext(Context);
  const { customer } = context;

  const label = 'Hyväksyn Otavamedian ';

  return (
    <div>
      {customer.tyrkyte && customer.tyrkyte.masterId ? (
        <CheckBoxField name="terms" label={label} legal />
      ) : (
        <CheckBoxField name="terms" label={label} legal />
      )}
    </div>
  );
};

export default Legal;
