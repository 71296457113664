import {
  BACKEND_IN_MAINTENANCE_MODE,
  EMPTY_CUSTOMER,
  OFFER_SET_CUSTOMER,
  ORDER_SET_CUSTOMER,
} from '../actions/actionTypes';

const emptyCustomer = {
  activePackageIds: [],
  firstName: null,
  lastName: null,
  postOffice: null,
  scores: [],
  streetAddress: null,
  zipCode: null,
};
const emptyTyrkyte = {
  masterId: null,
  targetGroupId: null,
  customer: {
    activePackageIds: [],
    firstName: null,
    lastName: null,
    postOffice: null,
    scores: [],
    streetAddress: null,
    zipCode: null,
  },
  expiresAt: null,
  ordered: null,
  shown: null,
};

export const initialState = {
  customer: emptyCustomer,
  tyrkyte: emptyTyrkyte,
  tyrkyteId: null,
  isLoadingCustomer: false,
  allowOrderMoreTyrkyteSkip: false,
};

// eslint-disable-next-line default-param-last
export const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case OFFER_SET_CUSTOMER: {
      return {
        ...initialState,
        customer: action.payload.tyrkyte.customer,
        tyrkyte: action.payload.tyrkyte ? action.payload.tyrkyte : emptyTyrkyte,
        tyrkyteId: action.payload.tyrkyteId,
      };
    }
    case ORDER_SET_CUSTOMER: {
      return {
        ...initialState,
        customer: action.payload.tyrkyte.customer,
        allowOrderMoreTyrkyteSkip: action.payload.allowOrderMoreTyrkyteSkip,
      };
    }
    case EMPTY_CUSTOMER: {
      return {
        ...state,
        customer: emptyCustomer,
        tyrkyte: emptyTyrkyte,
        tyrkyteId: null,
        isLoadingCustomer: false,
        allowOrderMoreTyrkyteSkip: false,
      };
    }
    // OTVMSUP-9, dispatch this when backend returns 503
    case BACKEND_IN_MAINTENANCE_MODE: {
      return {
        ...state,
        state: BACKEND_IN_MAINTENANCE_MODE,
      };
    }
    default: {
      return state;
    }
  }
};
