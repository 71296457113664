import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { Form, Grid } from 'semantic-ui-react';

import Context from '../../../context';
import { Recipient } from '../../../utils/util';
import SubscriberDetails from './SubscriberDetails';
import RecipientDetails from './RecipientDetails';
import CompanyDetails from './CompanyDetails';
import PaymentSelection from './PaymentSelection';
import t from '../../../locales/fi';

const RecipientAndPaymentSelection = (props) => {
  const {
    recipient,
    hidePaymentSelection = false,
    emailRequired = true,
  } = props;
  const context = useContext(Context);
  const { offer } = context;
  const { register, getValues } = useFormContext();
  const { selectedMagazineId } = getValues();
  const hideRecipientSelection = !!offer.payerCustomerId;

  /**
   * Check if product is allowed to be ordered for a friend
   */
  const canOrderForFriend = () => {
    // Selected magazine or first if none chosen
    const m = selectedMagazineId
      ? offer.magazines.find((mag) => mag.id === selectedMagazineId)
      : offer.magazines[0];
    return !(m && m.dontAllowOrderingForFriend);
  };

  /**
   * Check if product is allowed to be ordered for a company
   */
  const canOrderForCompany = () =>
    !(offer.requiresTyrkyte) && canOrderForFriend();

  return (
    <Grid id="recipientAndPaymentSelection">
      {!hideRecipientSelection && (
        <>
          <Grid.Row className="no-padding">
            <Grid.Column width={16}>
              <h2>Tilausjakso tulee</h2>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row id="recipientSelection" className="no-padding">
            <Grid.Column>
              <div className="elementList">
                <Form.Group>
                  <Form.Field>
                    <label>
                      <input
                        {...register('recipient')}
                        tabIndex="-1"
                        type="radio"
                        value="itselle"
                      />{' '}
                      Itselle
                    </label>
                  </Form.Field>
                  {canOrderForFriend() && (
                    <Form.Field>
                      <label>
                        <input
                          {...register('recipient')}
                          tabIndex="-1"
                          type="radio"
                          value="kaverille"
                        />{' '}
                        Lahjaksi
                      </label>
                    </Form.Field>
                  )}
                  {canOrderForCompany() && (
                    <Form.Field>
                      <label>
                        <input
                          {...register('recipient')}
                          tabIndex="-1"
                          type="radio"
                          value="yritykselle"
                        />{' '}
                        Yritykselle
                      </label>
                    </Form.Field>
                  )}
                </Form.Group>
              </div>
            </Grid.Column>
          </Grid.Row>
          {recipient === Recipient.TO_FRIEND && (
            <Grid.Row className="no-padding">
              <Grid.Column className="form-annotation form-annotation-gift">
                <p>{offer.options.giftInfoText}</p>
              </Grid.Column>
            </Grid.Row>
          )}
        </>
      )}

      {!offer.payerCustomerId
        && recipient !== Recipient.TO_COMPANY && ( // If maksajanasno is defined, show only input for recipientDetails
        <Grid.Row>
          {recipient === Recipient.TO_FRIEND && (
            <Grid.Column width={16}>
              <h2 className="pt-0">{t.PAYER_DETAILS}</h2>
            </Grid.Column>
          )}
          <Grid.Column width={16}>
            <SubscriberDetails emailRequired={emailRequired} />
          </Grid.Column>
        </Grid.Row>
      )}

      {recipient === Recipient.TO_FRIEND && (
        <Grid.Row>
          <Grid.Column width={16}>
            <h2 className="pt-0">{t.RECIPIENT_DETAILS}</h2>
          </Grid.Column>
          <Grid.Column width={16}>
            <RecipientDetails />
          </Grid.Column>
        </Grid.Row>
      )}

      {recipient === Recipient.TO_COMPANY && (
        <Grid.Row>
          <Grid.Column width={16}>
            <h2>Yrityksen tiedot</h2>
          </Grid.Column>
          <Grid.Column width={16}>
            <CompanyDetails />
          </Grid.Column>
        </Grid.Row>
      )}

      {!hidePaymentSelection && (
        <Grid.Row className="pt-0 pb-20">
          <Grid.Column>
            <PaymentSelection />
          </Grid.Column>
        </Grid.Row>
      )}
    </Grid>
  );
};

export default RecipientAndPaymentSelection;
