import React, { useLayoutEffect, useContext, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Form, Button, Grid } from 'semantic-ui-react';

import Context from '../../context';
import InputWrapper from '../util/InputWrapper';
import PackageBanner from '../main/PackageBanner';

import * as offerActions from '../../actions/offer';

const errorMessage = (offer, skipTyrkyte) => {
  const { disposableCode, errorText, magazines } = offer;
  if (errorText) {
    // Got magazines?
    if (magazines && magazines[0]) {
      // Disposable offer? Do not allow skip
      if (disposableCode) {
        return (
          <p
            className="errorText-longer"
            dangerouslySetInnerHTML={{ __html: errorText }}
          />
        );
      }
      // Allow skip if magazines are found
      return (
        <p className="errorText-longer">
          Jotain meni pieleen. Tarkista koodi ja yritä uudelleen. Pääset myös
          tekemään tilauksesi{' '}
          <span
            className="error-link"
            id="disableTyrkyte"
            onClick={skipTyrkyte}
          >
            Tästä.
          </span>
        </p>
      );
      // No magazines
    }
    return (
      <p
        className="errorText-no-magazines"
        dangerouslySetInnerHTML={{ __html: errorText }}
      />
    );
  }
  return null;
};

const TyrkyteForm = (props) => {
  const { onTyrkyteSubmit } = props;
  const context = useContext(Context);
  const { offer, offerDispatch } = context;

  const { couponCodeInfoText, magazines, errorText } = offer;

  const formMethods = useForm({
    defaultValues: {
      tyrkyteId: '',
    },
  });
  const [isValid, setIsValid] = useState(false);

  const validate = () => {
    if (!formMethods.getValues('tyrkyteId') && isValid) setIsValid(false);
    else setIsValid(true);
  };
  const isLukulahja = false;

  function cleanText(text) {
    return text
      .replace(/<[^>]+>/g, '')
      .replace(/&nbsp;/g, ' ')
      .replace(/&quot;/g, '"')
      .replace(/&amp;/g, '&')
      .replace(/&auml;/g, 'ä')
      .replace(/&ouml;/g, 'ö')
      .replace(/&Auml;/g, 'Ä')
      .replace(/&Ouml;/g, 'Ö');
  }

  function splitCouponCodeInfoHeader(offerHeaderAndText) {
    // Less ugly function to parse and check if tags are uppercased or lowercase
    // Parse offerHeaderAndText --> offerHeader
    // Values are from Sandbox / Campaign
    if (offerHeaderAndText) {
      let matcher = /.*/;

      if (offerHeaderAndText.match(/<H1>([^<]*?)<\/H1>/g)) {
        matcher = /<H1>([^<]*?)<\/H1>/g;
      }
      else if (offerHeaderAndText.match(/<h1>([^<]*?)<\/h1>/g)) {
        matcher = /<h1>([^<]*?)<\/h1>/g;
      }
      else if (offerHeaderAndText.match(/<H2>([^<]*?)<\/H2>/g)) {
        matcher = /<H2>([^<]*?)<\/H2>/g;
      }
      else if (offerHeaderAndText.match(/<h2>([^<]*?)<\/h2>/g)) {
        matcher = /<h2>([^<]*?)<\/h2>/g;
      }

      const offerHeaderTagged = offerHeaderAndText.match(matcher).toString();
      return cleanText(offerHeaderTagged);
    }
    return null;
  }

  function splitCouponCodeInfoText(offerHeaderAndText) {
    // Less ugly function to parse and check if tags are uppercased or lowercase
    // Parse offerHeaderAndText --> offerText
    // Values are from Sandbox / Campaign
    if (offerHeaderAndText) {
      let matcher = /.*/;

      if (offerHeaderAndText.match(/<P>([^<]*?)<\/P>/g)) {
        matcher = /<P>([^<]*?)<\/P>/g;
      }
      else if (offerHeaderAndText.match(/<p>([^<]*?)<\/p>/g)) {
        matcher = /<p>([^<]*?)<\/p>/g;
      }

      const offerTextTagged = offerHeaderAndText.match(matcher).toString();
      return cleanText(offerTextTagged);
    }
    return null;
  }

  const titleUnderlineHandler = () => {
    const titles = document.getElementsByTagName('h1');
    if (titles) {
      Array.from(titles).forEach((title) => {
        if (title.scrollHeight > 50) {
          title.classList.add('no-before');
        } else {
          title.classList.remove('no-before');
        }
      });
    }
  };

  /**
   * * OTVMSUP-171
   * * After DOM has rendered add event listener to resize.
   * * Removes (Adds 'no-before' class to element) underline from offering title if text overflows (e.g. text on 2 lines)
   */
  useLayoutEffect(() => {
    window.addEventListener('resize', titleUnderlineHandler);
    titleUnderlineHandler();
    return () => window.removeEventListener('resize', titleUnderlineHandler);
  }, []);

  return (
    <FormProvider {...formMethods}>
      <Grid className="wrapper-box-shadow stackable white-bg centered">
        {/*
              Checking for HEADER existence before function calls.
              Function splits header and text apart from a single field. */}
        {magazines[0] && couponCodeInfoText && (
          <Grid.Row style={{ background: 'white' }}>
            <PackageBanner offer={offer} isTyrkyteForm />
            {couponCodeInfoText.__html && (
              <h1 className="mt-0">
                {splitCouponCodeInfoHeader(couponCodeInfoText.__html)}
              </h1>
            )}
            {!couponCodeInfoText.__html && couponCodeInfoText?.__html && (
              <h1 className="mt-0">
                {splitCouponCodeInfoHeader(couponCodeInfoText.__html)}
              </h1>
            )}
            {couponCodeInfoText.__html === '' &&
              console.log('Leipätekstiä ei ole asetettu.')}
          </Grid.Row>
        )}

        {magazines[0] && couponCodeInfoText && (
          <Grid.Row
            mobile={16}
            tablet={16}
            computer={16}
            className="grid-tyrkyte-form-text-row pt-0 pb-0"
          >
            {couponCodeInfoText.__html && (
              <Grid.Column width={16}>
                <div style={{ textAlign: 'center' }}>
                  <p>{splitCouponCodeInfoText(couponCodeInfoText.__html)}</p>
                </div>
              </Grid.Column>
            )}
            {!couponCodeInfoText.__html && (
              <Grid.Column width={16}>
                <div style={{ textAlign: 'center' }}>
                  <p>{splitCouponCodeInfoText(couponCodeInfoText.__html)}</p>
                </div>
              </Grid.Column>
            )}
            {couponCodeInfoText.__html === '' &&
              console.log('Leipätekstiä ei ole asetettu.')}
          </Grid.Row>
        )}

        {magazines && magazines[0] && (
          <Grid.Row className="grid-tyrkyte-form-row">
            <Grid.Column mobile={8} tablet={8} computer={8}>
              <Form
                className="tyrkyte-form"
                onSubmit={formMethods.handleSubmit((values) => {
                  onTyrkyteSubmit(values.tyrkyteId, isLukulahja);
                })}
              >
                <Form.Field onChange={validate}>
                  <InputWrapper
                    name="tyrkyteId"
                    type="text"
                    label=""
                    style={{ width: '200px' }}
                  />
                </Form.Field>
                <Button
                  type="submit"
                  id="continue"
                  fluid
                  color="blue"
                  style={{ boxShadow: 'none' }}
                  disabled={!isValid}
                >
                  <img
                    alt="submit-arrow"
                    style={{
                      position: 'relative',
                      float: 'left',
                      marginLeft: -5,
                      marginTop: -2,
                      height: 17,
                      width: 17,
                      backgroundSize: 14,
                      content: '',
                      zIndex: 1,
                    }}
                    src="https://otavamedia.fi/wp-content/themes/om-otavamedia-theme/assets/img/icon-arrow.svg"
                  />
                  JATKA
                </Button>
              </Form>
            </Grid.Column>
          </Grid.Row>
        )}

        {errorText && (
          <Grid.Row>
            {errorMessage(offer, () => offerActions.skipTyrkyte(offerDispatch))}
          </Grid.Row>
        )}
      </Grid>
    </FormProvider>
  );
};

export default TyrkyteForm;
