import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { formatPrice } from '../../../utils/util';

const NumberOfPaymentsField = (props) => {
  const {
    magazine: {
      maxNumOfPayments: magazineMaxNumOfPayments,
      offerPrice,
    },
    offer,
  } = props;
  const { discountPercentage } = offer;
  const { control, getValues } = useFormContext();
  const { field } = useController({ control, name: 'numOfPayments' });
  const { numOfPayments } = getValues();

  return (
    <>
      <input
        name={field.name}
        value={field.value}
        ref={field.ref}
        type="number"
        style={{
          width: '20%',
          minWidth: '70px',
          height: '35px',
          padding: 'none',
          margin: 'none',
        }}
        min="1"
        tabIndex="-1"
        max={
          parseInt(magazineMaxNumOfPayments, 10) || parseInt(numOfPayments, 10)
        }
        onChange={(event) => {
          event.preventDefault();
          const maxNumOfPayments
            = magazineMaxNumOfPayments > 1
              ? magazineMaxNumOfPayments
              : numOfPayments;
          if (event.target.value <= parseInt(magazineMaxNumOfPayments, 10)) {
            field.onChange(event.target.value);
          } else {
            field.onChange(maxNumOfPayments);
          }
        }}
      />
      <span style={{ verticalAlign: 'bottom', margin: '5px' }}>
        x {formatPrice(offerPrice, discountPercentage, numOfPayments)}
      </span>
    </>
  );
};

export default NumberOfPaymentsField;
